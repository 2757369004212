/* /// COLORS ///
main logo: #104d7f
main color: #3596F2
shades:
-lighter-
10%: #285f8c
20%: #407199
30%: #5882a5
40%: #7094b2
50%: #88a6bf
60%: #9fb8cc
70%: #b7cad9

-darker-
10%: #0e4572
20%: #0d3e66
30%: #0b3659
40%: #0a2e4c
50%: #082740
60%: #061f33
70%: #051726

Complementary Color: #ff6f61
Analogous Color Scheme: lighter -- #4e7ab7 or darker -- #0c3b6f


*/

/*************************************/
/* GENERAL REUSABLE COMPONENTS */
/*************************************/

.container {
  /* 1140px */
  max-width: 120rem;
  padding: 0 3.2rem;
  margin: 0 auto 3rem auto;
}

.grid {
  display: grid;
  column-gap: 5.2rem;
  row-gap: 8rem;
}

.grid--2-cols {
  grid-template-columns: repeat(2, 1fr);
}
.grid--3-cols {
  grid-template-columns: repeat(3, 1fr);
}
.grid--4-cols {
  grid-template-columns: repeat(4, 1fr);
}

.grid--5-cols {
  grid-template-columns: repeat(5, 1fr);
}

/* .grid--2-rows{
  grid-template-rows: 2fr 0.5fr;
} */

.grid--center-v {
  align-items: center;
}

.navbar-margin {
  margin-top: 10rem;
}

.heading-primary,
.heading-primary--left,
.heading-secondary,
.heading-secondary--left,
.heading-tertiary {
  font-weight: 700;
  letter-spacing: -0.5px;
  margin: 5rem 0;
}

.heading-primary {
  font-size: 3rem;
  line-height: 1.05;
  text-align: center;
}

.heading-secondary {
  font-size: 2rem;
  line-height: 1.05;
  /* margin: 0 0 3rem 3rem; */
}

.heading-secondary--left {
  font-size: 2rem;
  line-height: 1.05;
  text-align: left;
  margin-bottom: 2rem;
  /* margin: 0 0 3rem 3rem; */
}

.heading-tertiary {
  font-size: 1.6rem;
  line-height: 1.05;
  margin-bottom: 3.2rem;
}

.subheading {
  display: block;
  font-size: 1.2rem;
  font-weight: 600;
  color: #004f76;
  text-transform: uppercase;
  margin-bottom: 1.6rem;
  letter-spacing: 0.75px;
}

.usual-text-color {
  color: #333 !important;
}

.btn:link,
.btn:visited {
  display: inline-block;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 600;
  padding: 0.8rem 1.6rem;
  border-radius: 9px;
  transition: all 0.3s;
}

.btn--full:link,
.btn--full:visited {
  background-color: #104d7f;
  color: white;
}

.btn--full:hover,
.btn--full:active {
  background-color: #104d7f;
  color: white;
}

.btn--outline:link,
.btn--outline:visited {
  background-color: white;
  color: #3596f2;
}

.btn--outline:hover,
.btn--outline:active {
  background-color: #e6f1f6;
  color: #3596f2;

  /* Trick to have the inside shadow*/
  box-shadow: inset 0 0 0 3px #fff;
}

.margin-right-sm {
  margin-right: 1.6rem !important;
}

.card-with-shadow {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* You can adjust the shadow properties as needed */
  transition: 0.3s;
}

.card-with-shadow:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4); /* You can adjust the shadow properties on hover */
}

/* ---------- BASE LISTING ---------- */

.base-box-text {
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 2rem;
}

.base-box-list-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 0;
  margin-bottom: 2rem;
  padding: 0;
}

.base-box-list {
  list-style: none;
  padding: 0;
}

.base-box-list-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* height: 3rem; */
  /* width: 15rem; */
  font-size: 1.2rem;
  font-weight: 400;
  color: #104d7f;
  line-height: 1.5;
  padding: 0.1rem 1rem 0.1rem 0.5rem;
  margin-bottom: 1rem;
  border-left: 5px solid #104d7f;
  transition: all 0.3s ease-in-out;
}

.base-box-list-item:hover {
  border-left: 5px solid #3596f2;
  color: #3596f2;
}

/* ---------- MARGINS, PADDINGS ---------- */
.mar-bot-1 {
  margin-bottom: 1rem !important;
}

.mar-bot-2 {
  margin-bottom: 2rem !important;
}

.mar-bot-3 {
  margin-bottom: 3rem;
}
.mar-bot-5 {
  margin-bottom: 5rem;
}

.mar-top-1 {
  margin-top: 1rem;
}
.mar-top-3 {
  margin-top: 3rem;
}
.mar-top-5 {
  margin-top: 5rem;
}

/* ---------- QUERIES ---------- */

@media (max-width: 360px) {
  .heading-primary {
    font-size: 2rem !important;
    word-wrap: break-word;
  }
}

@media (max-width: 499px) {
  .base-box-list-grid {
    grid-template-columns: 1fr;
  }
}

@media (min-width: 500px) and (max-width: 789px) {
  .base-box-list-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 790px) and (max-width: 996px) {
  .base-box-list-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
