.exam-category-page-container{
    margin-top: 3rem;
}

.exam-category-page-title{
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.5;
    margin-top: 1.5rem;
    margin-bottom: 3rem;
}

.exam-category-page-list{
    list-style: none;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 0
}

.exam-category-page-list-item{
    margin-bottom: 0.5rem;
}

.exam-category-page-list-item:hover{
    color: #3596F2
}