.exam-information-h1{
  font-size: 2.4rem;
  color: #104d7f;
  margin: 2rem 0;
}

.exam-information-h1:first-of-type{
  margin-top: 5rem;
}

.exam-information-h2{
  margin: 2rem 0;
  color: #104d7f;
}

/* .exam-information-h2:first-of-type{
  margin-top: 5rem;
} */

.exam-information-text{
  font-size: 1rem;
  line-height: 1.5;
}

.exam-information-text-last{
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 5rem;
}

.exam-information-text-list{
  font-size: 1rem;
  line-height: 1.5;
}

.exam-information-text-list li{
  list-style: none;
  border-left: #104d7f 2px solid;
  padding-left: 1rem;
  margin-bottom: 1rem;
}

.exam-information-text-list li ul{
  padding-left: 0;
}

.exam-information-text-list li ul li{
  list-style: none;
  border-left: none;
  padding-left: 0;
  margin-bottom: 0;
}
